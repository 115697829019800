import { Button } from "antd";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default function SectionContact({
  title,
  link,
  buttonText,
  isBlankLink,
  classNameTitle,
  onContactSale,
}) {
  const { t } = useTranslation("common");

  return (
    <section className="pb-20 common-new-contact">
      <div className="new-container">
        <div className="content bg-[#13138C] px-5 flex flex-col items-center justify-center gap-8 py-16 lg:py-12 rounded-xl">
          <h3
            className={`text-display-lg lg:text-display-md md:text-display-sm font-semibold text-white max-w-[680px] text-center ${classNameTitle}`}
          >
            {title || t("section_contact_title")}
          </h3>
          <div className="flex items-center gap-3">
            {onContactSale ? (
              <Button
                onClick={onContactSale}
                className="text-md font-semibold"
              >
                <span>{buttonText || t("button.contact_us")}</span>
              </Button>
            ) : (
              <Link href={link || "/contact-sales"} passHref>
                <a target={isBlankLink ? "_blank" : ""}>
                  <Button className="text-md font-semibold">
                    <span>{buttonText || t("button.contact_us")}</span>
                  </Button>
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
